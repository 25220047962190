@import '/src/styles/_partials/_reset.scss';
@import '/src/styles/_partials/_fonts.scss';

@import '/src/styles/_partials/_variables.scss';

@import '/src/styles/_partials/_header.scss';
@import '/src/styles/_partials/main_body.scss';

body {
  background-color: #ddd;
  font-family: var(--font-family);
}


