*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-display: swap;
  font-family: notoserif;
  font-style: normal;
  font-weight: normal;
  src: url("notoserif-bold.b7609ce4.woff2") format("woff2"), url("notoserif-bold.b0914bde.woff") format("woff");
  text-rendering: optimizeLegibility;
}

:root {
  --container-width: 1366px;
  --font-family: notoserif, serif;
  --shadow: -.2em .2em .1em #0003;
}

header {
  color: #fff;
  letter-spacing: .1em;
  text-align: center;
  background-color: #639;
  padding: 20px 40px;
  position: relative;
  overflow: hidden;
}

header h1 {
  color: #fcb997;
  z-index: 100;
  font-size: 3rem;
  position: relative;
}

@media only screen and (max-width: 600px) {
  header h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 480px) {
  header h1 {
    font-size: 1.3rem;
  }
}

main {
  max-width: var(--container-width);
  margin-inline: auto;
  padding: 40px;
}

main ul {
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

main li {
  cursor: pointer;
  height: 150px;
  text-align: center;
  transform-origin: center;
  width: 16.6667%;
  transition: all .1s;
}

main li:hover {
  transform: scale(.95);
  box-shadow: 0 0 #0000;
}

main li span {
  box-shadow: var(--shadow);
  height: 150px;
  width: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #000;
  border-radius: 50%;
  display: inline-block;
}

main .day-of-week-btn {
  justify-content: center;
  align-items: center;
  display: flex;
}

main .day-of-week-btn span {
  color: #fff;
  background-color: #639;
  justify-content: center;
  align-items: center;
  display: flex;
}

main .day-of-week-btn b {
  text-transform: capitalize;
  font-size: 1.2rem;
  display: block;
}

main .btn-1 span {
  background-image: url("james-1.c4652672.jpg");
}

main .btn-2 span {
  background-image: url("james-2.6e2c4ffc.jpg");
}

main .btn-3 span {
  background-image: url("james-3.b2836f1b.jpg");
}

main .btn-4 span {
  background-image: url("james-4.c0d171cd.jpg");
}

main .btn-5 span {
  background-image: url("james-5.165eea1c.jpg");
}

main .btn-6 span {
  background-image: url("james-6.290833e0.jpg");
}

main .btn-7 span {
  background-image: url("james-7.04170784.jpg");
}

main .btn-8 span {
  background-image: url("james-8.4365b777.jpg");
}

main .btn-9 span {
  background-image: url("james-9.2001d387.jpg");
}

main .btn-10 span {
  background-image: url("james-10.d5283401.jpg");
}

main .btn-11 span {
  background-image: url("james-11.f3c3c830.jpg");
}

main .btn-12 span {
  background-image: url("james-12.ed789c01.jpg");
}

main .btn-13 span {
  background-image: url("james-13.fa4f1527.jpg");
}

main .btn-14 span {
  background-image: url("james-14.380847fe.jpg");
}

main .btn-15 span {
  background-image: url("james-15.0bb4d440.jpg");
}

main .btn-16 span {
  background-image: url("james-16.8e8fbb8d.jpg");
}

main .btn-17 span {
  background-image: url("james-17.d81dae3b.jpg");
}

main .btn-18 span {
  background-image: url("james-18.d8460458.jpg");
}

main .btn-19 span {
  background-image: url("james-19.fe36bcc2.jpg");
}

main .btn-20 span {
  background-image: url("james-20.42a6d7f4.jpg");
}

main .btn-21 span {
  background-image: url("james-21.bde3045f.jpg");
}

main .btn-22 span {
  background-image: url("james-22.3d93d37d.jpg");
}

main .btn-23 span {
  background-image: url("james-23.7890381e.jpg");
}

@media only screen and (max-width: 1200px) {
  main li {
    width: 20%;
  }
}

@media only screen and (max-width: 992px) {
  main li {
    width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  main li {
    width: 33.3333%;
  }
}

@media only screen and (max-width: 600px) {
  main li {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  main {
    padding-inline: 20px;
  }
}

@media only screen and (max-width: 400px) {
  main li {
    height: 120px;
  }

  main li span {
    height: 120px;
    width: 120px;
  }
}

body {
  font-family: var(--font-family);
  background-color: #ddd;
}

/*# sourceMappingURL=index.54010b02.css.map */
