main {
  margin-inline: auto;
  max-width: var(--container-width);
  padding: 40px;

  ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    cursor: pointer;
    height: 150px;
    text-align: center;
    transform-origin: center center;
    transition: all 0.1s;
    width: calc(100% / 6);
    
    &:hover {
      box-shadow: 0 0 0 transparent;
      transform: scale(0.95);
    }

    span {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border: solid 2px #000;
      border-radius: 50%;
      box-shadow: var(--shadow);
      display: inline-block;
      height: 150px;
      width: 150px;
    }
  }

  .day-of-week-btn {
    align-items: center;
    display: flex;
    justify-content: center;

    span {
      align-items: center;
      background-color: rebeccapurple;
      color: #fff;
      display: flex;
      justify-content: center;
    }

    b {
      display: block;
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }

  @for $i from 1 through 23 {
    .btn-#{$i} span {
      background-image: url('/src/images/james-#{$i}.jpg');
    }
  }

  @media only screen and (max-width: 1200px) {
    li {
      width: 20%;
    }
  }

  @media only screen and (max-width: 992px) {
    li {
      width: 25%;
    }
  }

  @media only screen and (max-width: 767px) {
    li {
      width: calc(100% / 3);
    }
  }

  @media only screen and (max-width: 600px) {
    li {
      width: 50%;
    }
  }

  @media only screen and (max-width: 480px) {
    padding-inline: 20px;
  }

  @media only screen and (max-width: 400px) {
    li {
      height: 120px;

      span {
        height: 120px;
        width: 120px;
      }
    }
  }
}
