header {
  background-color: rebeccapurple;
  color: #fff;
  letter-spacing: 0.1em;
  overflow: hidden;
  padding: 20px 40px;
  position: relative;
  text-align: center;

  h1 {
    color: #fcb997;
    font-size: 3rem;
    position: relative;
    z-index: 100;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 1.3rem;
    }
  }
}
